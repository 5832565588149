import { createStore } from "vuex";
import { orderSubCancel, orderRefund, sysConfigList } from "@/api/api";
import { notification } from "ant-design-vue";

export interface State {
  refreshNeeded: boolean;
  configList: any[];
  subCheckedValue: boolean;
  subOrderIds: any[];
  refundRefreshNeeded: boolean;
  refundConfigList: any[];
  refundCheckedValue: boolean;
  refundOrderIds: any[];
}

const store = createStore<State>({
  state: {
    refreshNeeded: false,
    configList: JSON.parse(localStorage.getItem("SUBAPPIDLIST") || "[]"),
    subCheckedValue: localStorage.getItem("NotifNO") === "true" ? true : false,
    subOrderIds: [],

    refundRefreshNeeded: false,
    refundConfigList: JSON.parse(
      localStorage.getItem("REFUNDAPPIDLIST") || "[]"
    ),
    refundCheckedValue:
      localStorage.getItem("REFUNDNotifNO") === "true" ? true : false,
    refundOrderIds: [],
  },
  mutations: {
    // 触发页面刷新
    SET_REFRESH_NEEDED(state, isRefresh: boolean) {
      state.refreshNeeded = isRefresh;
    },
    //取消订阅弹窗开启/关闭
    SET_SUB_CHECKEDVALUE(state, checkedValue: boolean) {
      // state.subCheckedValue = checkedValue;
      localStorage.setItem("NotifNO", JSON.stringify(checkedValue));
    },
    SET_SUB_CONFIGLIST(state, data: any[]) {
      localStorage.setItem("SUBAPPIDLIST", JSON.stringify(data));
      state.configList = data;
    },

    // ==========退款触发页面刷新
    SET_REFUND_REFRESH_NEEDED(state, isRefresh: boolean) {
      state.refundRefreshNeeded = isRefresh;
    },
    //取消订阅弹窗开启/关闭
    SET_REFUND_CHECKEDVALUE(state, checkedValue: boolean) {
      // state.subCheckedValue = checkedValue;
      localStorage.setItem("REFUNDNotifNO", JSON.stringify(checkedValue));
    },  
    SET_REFUND_CONFIGLIST(state, data: any[]) {
      localStorage.setItem("REFUNDAPPIDLIST", JSON.stringify(data));
      state.refundConfigList = data;
    },
  },
  actions: {
    triggerRefresh({ commit }) {
      commit("SET_REFRESH_NEEDED", true);
    },
    resetRefresh({ commit }) {
      commit("SET_REFRESH_NEEDED", false);
    },
    changeSubCheckedValue({ commit }, data) {
      commit("SET_SUB_CHECKEDVALUE", data);
    },
    changeSubConfigList({ commit }, data) {
      commit("SET_SUB_CONFIGLIST", data);
    },
    //获取订阅检测
    getSubOrderWatch({ commit, state, dispatch }) {
      if (state.configList.length > 0) {
        dispatch("getRequestSubOrderData");
      } else {
        dispatch("getConfigList");
      }
    },
    getRequestSubOrderData({ state, dispatch }) {
      orderSubCancel({
        dbName: `${decodeURIComponent(state.configList.join(","))}`,
      }).then((res) => {
        if (res.data && res.data.length) {
          let data = res.data[0];
          state.subOrderIds = JSON.parse(
            localStorage.getItem("SubOrderIds") as string
          )
            ? JSON.parse(localStorage.getItem("SubOrderIds") as string)
            : [];
          if (!state.subOrderIds.includes(data.tradeNo)) {
            //弹出窗口
            if (state.subCheckedValue) {
              notification["warning"]({
                message: "取消订阅通知",
                description: `${data.packageName}-${data.userName} 用户取消订阅`,
              });
            }
            //通知刷新
            dispatch("triggerRefresh");
            state.subOrderIds.push(data.tradeNo);
            localStorage.setItem(
              "SubOrderIds",
              JSON.stringify(state.subOrderIds)
            );
          }
        }
      });
    },
    getConfigList({ state, dispatch }) {
      //全部app
      sysConfigList({
        isUse: 1,
      }).then((res: any) => {
        var configLists: any[] = [];
        res.data.forEach((val: any) => {
          configLists.push(val.dbName);
        });
        dispatch("changeSubConfigList", configLists);
        dispatch("getRequestSubOrderData");
      });
    },
    //  ==================退款监听
    triggerRefundRefresh({ commit }) {
      commit("SET_REFUND_REFRESH_NEEDED", true);
    },
    resetRefundRefresh({ commit }) {
      commit("SET_REFUND_REFRESH_NEEDED", false);
    },
    changeRefundCheckedValue({ commit }, data) {
      commit("SET_REFUND_CHECKEDVALUE", data);
    },
    changeRefundConfigList({ commit }, data) {
      commit("SET_REFUND_CONFIGLIST", data);
    },
    //获取订阅检测
    getRefundOrderWatch({ commit, state, dispatch }) {
      if (state.refundConfigList.length > 0) {
        dispatch("getRequestRefundOrderData");
      } else {
        dispatch("getRefundConfigList");
      }
    },
    getRequestRefundOrderData({ state, dispatch }) {
      orderRefund({
        dbName: `${decodeURIComponent(state.refundConfigList.join(","))}`,
      }).then((res) => {  
        if (res.data && res.data.length) {
          let data = res.data[0];
          state.refundOrderIds = JSON.parse(
            localStorage.getItem("RefundOrderIds") as string
          )
            ? JSON.parse(localStorage.getItem("RefundOrderIds") as string)
            : [];
          if (!state.refundOrderIds.includes(data.tradeNo)) {
            //弹出窗口
            if (state.refundCheckedValue) {
              notification["error"]({
                message: "退款通知",
                description: `${data.packageName}-${data.userName} 用户退款通知`,
              });
            }
            //通知刷新
            dispatch("triggerRefundRefresh");
            state.refundOrderIds.push(data.tradeNo);
            localStorage.setItem(
              "RefundOrderIds",
              JSON.stringify(state.refundOrderIds)
            );
          }
        }
      });
    },
    getRefundConfigList({ state, dispatch }) {
      //全部app
      sysConfigList({
        isUse: 1,
      }).then((res: any) => {
        var configLists: any[] = [];
        res.data.forEach((val: any) => {
          configLists.push(val.dbName);
        });
        dispatch("changeRefundConfigList", configLists);
        dispatch("getRequestRefundOrderData");
      });
    },
  },
});

export default store;
